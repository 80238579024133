<template>
  <TitleMeeting :title="title" />
  <CurrentMeeting />
  <Calendar :legends="[{color: 'var(--color--pink)', text:'RDV proposés par mon précédent coach'}]" @update-title="updateTitle" />
</template>

<script>
import TitleMeeting from "@/components/Meeting/TitleMeeting";
import CurrentMeeting from "@/components/Meeting/CurrentMeeting";
import Calendar from "@/components/Calendar/Calendar";

export default {
  name: "MeetingView",
  components: {TitleMeeting, Calendar, CurrentMeeting},
  data() {
    return {
      title: 'Prenez rendez-vous'
    }
  },
  methods: {
    updateTitle(title) {
      this.title = title
    }
  }
}
</script>