<template>
  <div class="current-meeting"  v-for="(meeting, index) in pendingMeetings" :key="index">
    <div class="meeting">
      Vous avez déjà un rendez-vous le {{ formatedDate(meeting.starts_at) }}.<br />
      <a href="#" @click.prevent="$router.push({name: 'MeetingConfirmation', params: {id: meeting.id}})">Voir mon rendez-vous</a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import meetingsService from '@/services/meetings.service';

export default {
  name: "CurrentMeeting",
  data() {
    return {
      date: '',
      pendingMeetings: []
    }
  },
  mounted() {
    meetingsService.getMeetings('pending', moment().format())
        .then((response) => {
            this.pendingMeetings = response.data.data.meetings;
        });
  },
  methods: {
    formatedDate(date) {
      return moment(date).format('dddd Do MMMM à HH:mm').toLowerCase()
    }
  }
}
</script>